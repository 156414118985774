import React, { useEffect, useState } from 'react';
import firebase from 'firebase';
import { db, auth, arrayUnion, arrayRemove } from '../firebase';
import { useAuthState } from "react-firebase-hooks/auth";
import PostList from '../components/posts/PostList';
import EditProfile from '../components/modals/EditProfile';
import FollowButton from '../components/buttons/FollowButton';
import useUser from '../components/hooks/useUser';
import { useParams } from 'react-router-dom';
import { PencilAltIcon } from "@heroicons/react/solid";
import LoginSignup from '../components/modals/LoginSignup';

export default function Profile() {
  const [numPosts, setNumPosts] = useState(0);
  const { urlUsername } = useParams();
  const user = useUser(urlUsername);
  const [currentUser] = useAuthState(auth);
  const [numFollowers, setNumFollowers] = useState();
  const [following, setFollowing] = useState();
  const [showEditProfile, setShowEditProfile] = useState(false);
  const [showLoginSignup, setShowLoginSignup] = useState(false);
  
  // firebase
  useEffect(() => {
    window.scrollTo(0, 0);
    if (user.followedBy){
      setNumFollowers(user.followedBy.length);
      setFollowing(user.followedBy.includes(currentUser?.uid));
      // fix this
      db.collection('posts').where('userId', '==', user.id).orderBy('timestamp', 'desc').get()
      .then(innerQuerySnapshot => {
        // everytime a new post is added, this code runs
        setNumPosts(innerQuerySnapshot.docs.length)
      })
    }
  }, [urlUsername, user?.followedBy?.length]);

  const handleEditProfileCancel = () => {
    setShowEditProfile(false);
  }

  const handleLoginSignupCancel = () => {
    setShowLoginSignup(false);
  }

  function renderPosts(){
    
    if (numPosts){
      return <PostList className="col-span-1 md:col-span-2" userId={user.id} />
    } else {
      return (
        <div className="flex justify-center lg:justify-end w-full">
          <div className="flex lg:justify-end lg:items-start col-span-1 max-w-xl w-full mx-3">
            <div className="rounded-lg bg-gray-900 mt-6 w-full border-dashed border-4 border-gray-700 p-5 py-20">
              <div className="flex justify-center w-full text-gray-500 font-bold">
                <p>This user hasn't made any posts yet 🥲</p>
              </div>
            </div>  
          </div>
        </div>
      )
    }
  }

  const handleFollow = () => {
    if (currentUser){
      if (!following){
        setNumFollowers(numFollowers + 1);
        setFollowing(true);
        db.collection("users").doc(user.id).update({
          followedBy: arrayUnion(currentUser.uid)
        }).then(_ => {
          db.collection("notifications").add({
            timestamp: firebase.firestore.FieldValue.serverTimestamp(),
            userId: user.id,
            likerUserId: currentUser.uid,
            message: "just followed you!",
            seen: false
          })
        })

      } else {
        setNumFollowers(numFollowers - 1);
        setFollowing(false);
        db.collection("users").doc(user.id).update({
          followedBy: arrayRemove(currentUser.uid)
        });
      }
    } else {
      setShowLoginSignup(true)
    }
  }

  function renderUsername(){
    if (user.role == 'admin'){
      return (
        <div className="text-purple-500 text-xl font-bold">
          <p>{user.username}</p>
        </div>
      );
    } else if (user.role == 'mod'){
      return (
        <div className="text-green-600 text-xl font-bold">
          <p>{user.username}</p>
        </div>
      );
    } else {
      return (
        <div className="text-gray-200 text-xl font-bold">
          <p>{user.username}</p>
        </div>
      )
    }
  }

  return (
    <>
      {/* Edit profile modal */}
      <LoginSignup showModal={showLoginSignup} onCancel={handleLoginSignupCancel} />
      <EditProfile showModal={showEditProfile} onCancel={handleEditProfileCancel} />
      <div className="App bg-gray-900 grid grid-cols-1 lg:grid-cols-3 gap-0">
        {/* Profile panel */}
        <div className="flex justify-center lg:justify-end w-full">
          <div className="flex lg:fixed lg:justify-end lg:items-start col-span-1 max-w-xl w-full mx-2 lg:mx-0">
            <div className="rounded-lg bg-gray-800 mt-6 w-full lg:w-1/2 border border-gray-700 p-5">
              <div className="flex justify-center w-full">
                <img 
                  className="rounded-full h-40 w-40 bg-gray-900 flex items-center justify-center overflow-hidden object-cover"
                  src={user.imageUrl}
                  alt="Avatar"
                />
              </div>
              <div className="flex justify-center pt-5">
                {renderUsername()}
              </div>
              <div className="flex justify-center">
                <div className="text-gray-300 text-sm font-med">
                  <p>Followers: {numFollowers}</p>
                </div>
              </div>
              <div className="flex justify-center">
                <div className="text-gray-300 text-sm font-med">
                  <p>Joined: {user.timestamp}</p>
                </div>
              </div>
              <div className="flex justify-center">
                <div className="text-gray-300 text-sm font-med">
                  <p>Pepe's Posted: {numPosts}</p>
                </div>
              </div>           
              {(currentUser && (currentUser.uid === user.id)) ? (
                <div className="flex justify-center pt-5">
                  <button className="text-white text-sm bg-gray-900 shadow-xl rounded-full py-2 px-4" onClick={() => setShowEditProfile(true)}>
                    Edit profile <PencilAltIcon className="inline-block h-5 w-5 pb-1" />
                  </button>
                </div>
              ):(
                <FollowButton onClick={handleFollow} following={following} />
              )}
            </div>
          </div>
        </div>

        {renderPosts()}

      </div>
    </>
  );
}