import React, { useState, Fragment, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import firebase from "firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { db, storage, auth } from '../../firebase';
import { Transition, Dialog } from '@headlessui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudUploadAlt } from '@fortawesome/free-solid-svg-icons';
import { v4 as uuidv4 } from 'uuid';


export default function NewPost(props) {
  const {showModal, onCancel} = props;
  const cancelButtonRef = useRef(null);
  const [currentUser] = useAuthState(auth);
  const [image, setImage] = useState(null);
  const [imageSize, setImageSize] = useState(null)
  const [imageType, setImageType] = useState(null)
  const [disablePost, setDisablePost] = useState(false)
  const [errorText, setErrorText] = useState('')
  const [progress, setProgress] = useState(0);
  const [showProgress, setShowProgress] = useState(false);
  const [caption, setCaption] = useState('');

  const history = useHistory();

  const handleChange = (e) => {

    // check image size
    if (!e.target.files[0] || e.target.files[0].length === 0) {
      setImage(null)
      return
    }

    // check image type
    if (e.target.files[0].type != "image/jpeg" && e.target.files[0].type != "image/png" && e.target.files[0].type != "image/gif") {
      setErrorText("Your Pepe must be an image.")
      setImage(null)
      setDisablePost(true)
      return
    } else if (e.target.files[0].size > 3145728){
      setErrorText("This pepe exceeds the maximum image size. Please keep the image less than 3MB.")
      setDisablePost(true)
      return
    } else {
      setErrorText('')
    }

    // set state values if above tests pass
    if (e.target.files[0]) {
      setImage(e.target.files[0])
      setDisablePost(false)
    }

  }

  const closePost = () => {
    onCancel();
    setImage(null);
  }

  const handleUpload = () => {
    setShowProgress(true);
    const imageId = uuidv4();
    var imageName = '';
    const fileExt = "." + image.name.split('.').pop();
    if (image.name.includes('.')){
      imageName = imageId + fileExt;
    } else {
      imageName = imageId;
    }

    const uploadTask = storage.ref(`images/${imageName}`).put(image);

    uploadTask.on(
      "state changed",
      (snapshot) => {
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        setProgress(progress);
      },
      (error ) => {
        alert(error.message);
      },
      () => {
        storage
          .ref("images")
          .child(imageName)
          .getDownloadURL()
          .then(url => {
            
            // post image
            db.collection("posts").add({
              timestamp: firebase.firestore.FieldValue.serverTimestamp(),
              caption: caption,
              imageUrl: url,
              likedBy: [],
              likedByHistory: [],
              likes: 0,
              userId: currentUser.uid
            }).then(_ => {
              history.go(0);
            });
            setProgress(0);
            setCaption("");
            setImage(null);
            setShowProgress(false);
            onCancel();
          })
      }
    )
  }

  return (
    <Transition.Root show={showModal} as={Fragment}>
      <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" initialFocus={cancelButtonRef} onClose={onCancel}>
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-gray-800 rounded-lg text-center overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full px-5">
              <div className="flex justify-center pt-5">
                <p className="text-gray-300 font-bold">
                  New Post
                </p>
              </div>
              <div className="flex justify-center">
                <div className="max-w-sm pt-5">
                  <p className="text-gray-300 text-base">
                    Sign up today to contribute to our growing collection of pepe art 🐸  
                  </p> 
                </div>
              </div>
              <div className="justify-center">
                <div className="pt-8 justify-center space-y-3">
                  {image && (
                    <>
                      <div className="flex justify-center">
                        <img
                          className="rounded-lg w-full"
                          src={URL.createObjectURL(image)}
                          alt="Thumb"
                        />
                      </div>
                    </>
                  )}
                  <label
                    className="w-full inline-flex justify-center rounded-md border border-indigo-500 shadow-sm px-4 py-2 bg-indigo-500 text-base font-medium text-white hover:bg-indigo-700 hover:border-indigo-700 focus:outline-none sm:ml-0 sm:w-auto sm:text-sm"
                  >
                    <span className="text-md pr-2">
                      <FontAwesomeIcon icon={faCloudUploadAlt} />
                    </span>
                    {image ? "Change Image" : "Upload Image"}
                    <input
                      className="hidden"
                      accept="image/*"
                      type="file"
                      onChange={handleChange}
                    />
                  </label>
                  <textarea
                    className="pt-2 pl-2 rounded-lg bg-gray-800 text-gray-200 border border-gray-400 focus:border-green-500 focus:outline-none w-full"
                    type="text"
                    placeholder="Enter a caption for this epic pepe..."
                    rows="4"
                    onChange={event => setCaption(event.target.value)}
                    value={caption}
                  />
                  {showProgress ? (
                    // {/* <progress 
                    //   className="overflow-hidden h-2 text-xs flex rounded bg-blue-500 text-blue-500 w-full"
                    //   value={progress}
                    //   max="100"
                    // /> */}
                    <div className="w-full bg-gray-200 rounded-full h-2.5">
                      <div id="bar" className="transition-all ease-out duration-1000 h-full rounded-full bg-green-500 relative" style={{width: `${progress}%`}} ></div>
                    </div>
                  ): null}
                </div> 
              </div>
              <p className= "text-red-500 pt-1 pb-4">{errorText}</p>
              <div className="bg-gray-800 pb-3 sm:px-6 sm:flex sm:flex-row-reverse sm:justify-center md:flex md:justify-center max-w-lg">
                <button
                  type="button"
                  disabled={disablePost}
                  className="w-full inline-flex justify-center rounded-md border border-green-500 shadow-sm px-4 py-2 bg-green-500 text-base font-medium text-white hover:bg-green-700 hover:border-green-700 focus:outline-none sm:ml-0 sm:w-auto sm:text-sm"
                  onClick={handleUpload}
                >
                  Post
                </button>
                <button
                  type="button"
                  className="mt-3 sm:mt-0 md:mt-0 lg:mt-0 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-gray-800 text-base font-medium text-gray-300 hover:border-red-500 hover:text-red-500 focus:outline-none sm:mr-3 sm:ml-0 sm:w-auto sm:text-sm"
                  onClick={closePost}
                  ref={cancelButtonRef}
                >
                  Cancel
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}