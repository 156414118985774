import React, { useEffect, useState } from 'react';
import { db } from '../../firebase';
import Post from './Post';

export default function PostList(props) {
  const {userId = null, sortByLikes = false} = props;
  const [posts, setPosts] = useState([]);
  
  // firebase
  useEffect(() => {
    let dbResults = null;
    if (userId) {
      dbResults = db.collection('posts').where('userId', '==', userId).orderBy('timestamp', 'desc').get();
    } else if (sortByLikes) {
      dbResults = db.collection('posts').orderBy('likes', 'desc').orderBy('timestamp', 'desc').get();
    } else {  
      dbResults = db.collection('posts').orderBy('timestamp', 'desc').get();
    }

    dbResults.then((querySnapshot) => {
      setPosts(querySnapshot.docs.map(doc => ({
        id: doc.id, 
        ...doc.data()
      })))
    }) 
    .catch((e) => {
      console.error(e);
      setPosts([]);
    });
  }, [userId, sortByLikes]);

  return (
    <div>
      {
        posts.map((post) =>(
          <Post key={post.id} post={post} />
        ))
      }
    </div>
  );
}