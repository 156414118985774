import './App.css';
import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import { StateProvider, initialState } from './states/notification-drawer-state';
import { useGlobalStateValue } from './states/notification-drawer-state';

import reducer from './states/reducer';
import PepeFeed from './views/PepeFeed';
import TopPepes from './views/TopPepes';
import NavBar from './components/NavBar';
import Profile from './views/Profile';
import PostView from './views/PostView';
import Footer from './components/Footer';
import NotificationDrawer from './components/notifications/NotificationDrawer';

function App() {
  return (
    <StateProvider initialState={initialState} reducer={reducer}>
      <Router>
        <div className="min-h-screen pb-10">  
          <NavBar />
          <Toaster position="top-center" />
          <NotificationDrawer />
          <Switch>
            <Route exact path="/" component={PepeFeed} />
            <Route path="/top-pepes" component={TopPepes} />
            <Route path="/seasonal-pepes" component={PepeFeed} />
            <Route path="/u/:urlUsername" component={Profile} />
            <Route path="/post/:urlPostId" component={PostView} />
          </Switch>
        </div>
        <Footer />
      </Router>
    </StateProvider> 
  );
}

export default App;