import React, { useEffect, useState, Fragment, useRef } from 'react';
import { Tab, Transition, Dialog } from '@headlessui/react';
import firebase from "firebase";
import { db, auth } from '../../firebase';
import { useAuthState } from "react-firebase-hooks/auth";
import toast from 'react-hot-toast';
import { useHistory } from 'react-router';

export default function LoginSignup(props) {
  const {showModal, onCancel} = props;
  const cancelButtonRef = useRef(null);
  const [profilePicUrl, setProfilePicUrl] = useState('https://firebasestorage.googleapis.com/v0/b/pepehub-8734e.appspot.com/o/profilePics%2Fdefault-profile-pic.jpeg?alt=media&token=c5a95454-7ab9-40a3-8ba6-d78cfc9b56b9');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');
  const [currentUser] = useAuthState(auth);
  
  /* iOS re-orientation fix */
  if (navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/iPad/i)) {
    /* iOS hides Safari address bar */
    window.addEventListener("load",function() {
        setTimeout(function() {
            window.scrollTo(0, 1);
        }, 1000);
    });
  }

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((authUser) => {
      if (authUser){
        // user logged in
        console.log("user logged in");
        
      } else {
        // user has logged out...
        setEmail('');
        setPassword('');
      }
    })
    return () => {
      // perform some cleanup
      unsubscribe()
    }
  }, [currentUser, username])

  const signUp = (event) => {
    event.preventDefault();
    db.collection('users').where("username", "==", username).get().then((data) => {
      if (!data.empty){
        toast.error("Theres already a user with that username",
          {
            style: {
              border: '2px solid #111827',
              borderRadius: '5px',
              background: '#1F2937',
              color: '#fff',
            },
          }
        )
      } else {
        auth.createUserWithEmailAndPassword(email, password)
        .then((authUser) => {
          db.collection('users').doc(authUser.user.uid).set({
            timestamp: firebase.firestore.FieldValue.serverTimestamp(),
            imageUrl: 'https://firebasestorage.googleapis.com/v0/b/pepehub-8734e.appspot.com/o/profilePics%2Fdefault-profile-pic.jpeg?alt=media&token=c5a95454-7ab9-40a3-8ba6-d78cfc9b56b9',
            followedBy: [],
            username: username.toLowerCase(),
            email: email.toLowerCase(),
            role: null
          });
          return authUser.user.updateProfile({
            displayName: username,
            photoURL: profilePicUrl
          })
          .then(() => {
            onCancel();
          })
        })
        .catch((e) => {
          toast.error(e.message,
          {
            style: {
              border: '2px solid #111827',
              borderRadius: '5px',
              background: '#1F2937',
              color: '#fff',
            },
          }
        )
        })
      }
    })
    .catch((e) => {
      alert(e);
    })
  }
  
  const login = (event) => {
    event.preventDefault();
    auth.signInWithEmailAndPassword(email, password)
      .then((authUser) => {
        setProfilePicUrl(authUser.user.photoURL)
        onCancel();
        toast.success("Success!",
        {
          style: {
            background: '#3C7D37',
            color: '#fff',
          },
        })
      })
      .catch((error) => alert(error.message))
  }

  return (
    <Transition.Root show={showModal} as={Fragment}>
      <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" initialFocus={cancelButtonRef} onClose={onCancel}>
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-gray-900 rounded-lg text-center overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              <Tab.Group>
                <Tab.List className="grid grid-cols-2 gap-2 pt-5 text-center mx-8">
                  <Tab className={({ selected }) =>
                    selected 
                    ? 'text-gray-300 bg-gray-800 rounded-md font-bold py-2 col-auto w-full focus:outline-none' 
                    : 'text-gray-300 bg-gray-900 hover:bg-gray-800 rounded-md font-bold py-2 col-auto w-full focus:outline-none'
                  }>
                    Sign Up
                  </Tab>
                  <Tab className={({ selected }) =>
                    selected 
                    ? 'text-gray-300 bg-gray-800 rounded-md font-bold py-2 col-auto w-full focus:outline-none' 
                    : 'text-gray-300 bg-gray-900 hover:bg-gray-800 rounded-md font-bold py-2 col-auto w-full focus:outline-none'
                  }>
                    Login
                  </Tab>
                </Tab.List>
                <Tab.Panels>
                  <Tab.Panel className="h-auto md:h-96">
                    <div className="flex justify-center pt-3">
                      <div className="max-w-sm pt-5">
                        <p className="text-gray-300 text-base">
                          Sign up today to contribute to our growing collection of pepe art 🐸  
                        </p>
                      </div>
                    </div>
                    <form>
                      <div className="flex justify-center px-5">
                        <div className="py-8 justify-center max-w-sm space-y-3">
                          <input
                            className="py-2 pl-2 rounded-lg bg-gray-900 text-gray-200 border border-gray-400 focus:border-green-500 focus:outline-none w-full"
                            type="text"
                            placeholder="username"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            required
                          />
                          <input
                            className="py-2 pl-2 rounded-lg bg-gray-900 text-gray-200 border border-gray-400 focus:border-green-500 focus:outline-none w-full"
                            type="text"
                            placeholder="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                          />
                          <input
                            className="py-2 pl-2 rounded-lg bg-gray-900 text-gray-200 border border-gray-400 focus:border-green-500 focus:outline-none w-full"
                            type="password"
                            placeholder="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                          />
                        </div>
                      </div>
                      <div className="bg-gray-900 px-4 pb-3 sm:px-6 sm:flex sm:flex-row-reverse sm:justify-center md:justify-center md:flex max-w-lg pt-9">
                        <button
                          type="submit"
                          className="w-full inline-flex justify-center rounded-md border border-green-500 shadow-sm px-4 py-2 bg-green-500 text-base font-medium text-white hover:bg-green-700 hover:border-green-700 focus:outline-none sm:ml-3 sm:w-auto sm:text-sm md:flex-1"
                          onClick={signUp}
                        >
                          Sign Up
                        </button>
                        <button
                          type="button"
                          className="mt-3 sm:mt-0 md:mt-0 lg:mt-0 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-gray-900 text-base font-medium text-gray-300 hover:border-red-500 hover:text-red-500 focus:outline-none sm:mr-3 sm:ml-3 sm:w-auto sm:text-sm md:flex-1"
                          onClick={() => onCancel()}
                          ref={cancelButtonRef}
                        >
                          Cancel
                        </button>
                      </div>
                    </form>
                  </Tab.Panel>
                  <Tab.Panel className="h-auto md:h-96">
                    <div className="flex justify-center pt-3">
                      <div className="max-w-sm pt-5">
                        <p className="text-gray-300 text-base">
                          Please enter your email and password 🤪 
                        </p>
                      </div>
                    </div>
                    <form>
                      <div className="flex justify-center px-5">
                        <div className="py-8 justify-center space-y-3">
                          <input
                            className="py-2 pl-2 rounded-lg bg-gray-900 text-gray-200 border border-gray-400 focus:border-green-500 focus:outline-none w-full"
                            type="text"
                            placeholder="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                          />
                          <input
                            className="py-2 pl-2 rounded-lg bg-gray-900 text-gray-200 border border-gray-400 focus:border-green-500 focus:outline-none w-full"
                            type="password"
                            placeholder="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                          />
                        </div> 
                      </div>
                      <div className="bg-gray-900 px-4 pb-3 sm:px-6 sm:flex sm:flex-row-reverse sm:justify-center md:flex md:justify-center max-w-lg p-28">
                        <button
                          type="submit"
                          className="w-full inline-flex justify-center rounded-md border border-green-500 shadow-sm px-4 py-2 bg-green-500 text-base font-medium text-white hover:bg-green-700 hover:border-green-700 focus:outline-none sm:ml-3 sm:w-auto sm:text-sm md:flex-1"
                          onClick={login}
                        >
                          Login
                        </button>
                        <button
                          type="button"
                          className="mt-3 sm:mt-0 md:mt-0 lg:mt-0 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-gray-900 text-base font-medium text-gray-300 hover:border-red-500 hover:text-red-500 focus:outline-none sm:mr-3 sm:ml-3 sm:w-auto sm:text-sm md:flex-1"
                          onClick={() => onCancel()}
                          ref={cancelButtonRef}
                        >
                          Cancel
                        </button>
                      </div>
                    </form>
                  </Tab.Panel>
                </Tab.Panels>
              </Tab.Group>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}