import firebase from "firebase";

const firebaseApp = firebase.initializeApp({
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MSG_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID
});

const db = firebaseApp.firestore();
const auth = firebase.auth();
const storage = firebase.storage();
const arrayUnion = (value) => firebase.firestore.FieldValue.arrayUnion(value);
const arrayRemove = (value) => firebase.firestore.FieldValue.arrayRemove(value);
const increment = firebase.firestore.FieldValue.increment(1);
const decrement = firebase.firestore.FieldValue.increment(-1);
const documentId = firebase.firestore.FieldPath.documentId();

export { db, auth, storage, arrayUnion, arrayRemove, increment, decrement, documentId };