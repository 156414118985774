import React, { useState, useEffect } from "react";
import ShareModalMobile from "./ShareModalMobile";
import ShareModalDesktop from "./ShareModalDesktop";

export default function ShareModal(props) {
  const {showModal, onCancel, postUrl} = props;

  const [width, setWidth] = useState(window.innerWidth);
  let isMobile = (width < 768)

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);
  
  return (isMobile ? (
    <ShareModalMobile showModal={showModal} onCancel={onCancel} postUrl={postUrl} />
  ):(
    <ShareModalDesktop showModal={showModal} onCancel={onCancel} postUrl={postUrl} />
  ));
}