import React from 'react';
import { ShareIcon } from "@heroicons/react/solid";

export default function ShareButton(props) {
  const {onClick} = props

  return (
    <button className="inline-block cursor-pointer" onClick={onClick}>
      <ShareIcon className="h-7 w-7" />
    </button>
  );
}