import React from 'react';
import { PlusIcon, MinusIcon } from "@heroicons/react/solid";

export default function FollowButton(props) {
  const {onClick, following} = props;
  const text = following ? 'Unfollow' : 'Follow';
  const icon = following 
    ? <MinusIcon className="inline-block h-5 w-5 pb-1" />
    : <PlusIcon className="inline-block h-5 w-5 pb-1" />

  return (
    <div className="flex justify-center pt-5">
      <button className="text-white text-sm bg-gray-900 shadow-xl rounded-full py-2 pl-4 pr-2" onClick={onClick}>
        {text} {icon}
      </button>
    </div>
  );
}