import React, {
  createContext, 
  useContext, 
  useReducer,
} from 'react';

// create the global context
const StateContext = createContext();

export const StateProvider = ({reducer, initialState, children}) => (
  <StateContext.Provider value={useReducer(reducer, initialState)}>
    {children}
  </StateContext.Provider>
);

export const useGlobalStateValue = () => useContext(StateContext);

export const initialState = {
  showNotificationDrawer: false,
  newNotification: false // new
};