import { useEffect, useState } from "react";
import { db } from '../../firebase';

export default function useUser(urlUsername){
  const [user, setUser] = useState({});

  useEffect(() => {
    db.collection('users').where('username', '==', urlUsername.toLowerCase()).get()
    .then(querySnapshot => {
      const userData = querySnapshot.docs[0].data();
      setUser({
        id: querySnapshot.docs[0].id,
        username: userData.username,
        imageUrl: userData.imageUrl,
        timestamp: userData.timestamp.toDate().toDateString(),
        followedBy: userData.followedBy,
        role: userData.role
      })
    })
  }, [urlUsername])
  return user;
}