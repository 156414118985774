import React, { useEffect, useState, Fragment } from "react";
import { Link } from "react-router-dom";
import { db, auth, arrayUnion, arrayRemove, increment, decrement } from '../../firebase';
import { useAuthState } from "react-firebase-hooks/auth";
import firebase from "firebase";
import moment from "moment";
import Comments from "./Comments";
import LoginSignup from "../modals/LoginSignup";
import PostMenuModal from "../modals/PostMenuModal";
import PostMenuButton from "../buttons/PostMenuButton";
import ShareModal from "../modals/ShareModal";
import ShareButton from "../buttons/ShareButton";
import { HeartIcon as HeartIconOutline } from "@heroicons/react/outline";
import { HeartIcon as HeartIconSolid } from "@heroicons/react/solid";

export default function Post({post}) {
  const [creator, setCreator] = useState({});
  const [numLikes, setNumLikes] = useState(post.likedBy.length);
  const [currentUser] = useAuthState(auth);
  const [userRole, setUserRole] = useState(null);
  const [liked, setLiked] = useState(post.likedBy.includes(currentUser?.uid));
  const [likedByHistory, setLikedByHistory] = useState(post.likedByHistory.includes(currentUser?.uid));
  const [showPostMenu, setShowPostMenu] = useState(false);
  const [showLoginSignup, setShowLoginSignup] = useState(false);
  const [showShareModal, setShowShareModal] = useState(false);
  const defaultAvatarUrl = 'https://firebasestorage.googleapis.com/v0/b/pepehub-8734e.appspot.com/o/profilePics%2Fdefault-profile-pic.jpeg?alt=media&token=c5a95454-7ab9-40a3-8ba6-d78cfc9b56b9';
  const postUrl = "https://www.pepehub.art/post/" + post.id;

  // firebase
  useEffect(() => {
    if (currentUser){
      db.collection('users').doc(currentUser.uid).get()
      .then(querySnapshot => {
        const userData = querySnapshot.data();
        setUserRole(userData.role);
      })
    }; 
    db.collection('users').doc(post.userId).get().then(doc => {
      setCreator(doc.data())
    });
  }, [currentUser, post.userId]);

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }

  const handlePostMenuCancel = () => {
    setShowPostMenu(false);
  }

  const handleLoginSignupCancel = () => {
    setShowLoginSignup(false);
  }

  const handleShareModalCancel = () => {
    setShowShareModal(false);
  }

  const handleLike = () => {
    if (currentUser){
      if (!liked){
        setNumLikes(numLikes + 1);
        setLiked(true);
        console.log(post);
        if (!likedByHistory){
          db.collection("posts").doc(post.id).update({
            likedBy: arrayUnion(currentUser.uid),
            likedByHistory: arrayUnion(currentUser.uid),
            likes: increment
          }).then(_ => {
            db.collection("notifications").add({
              timestamp: firebase.firestore.FieldValue.serverTimestamp(),
              userId: post.userId,
              likerUserId: currentUser.uid,
              message: "liked your pepe",
              postId: post.id,
              seen: false
            })
          }).catch((e) => {alert(e)})
        }
        setLikedByHistory(true);
      } else {
        setNumLikes(numLikes - 1);
        setLiked(false);
        db.collection("posts").doc(post.id).update({
          likedBy: arrayRemove(currentUser.uid),
          likes: decrement
        });
      }
    } else {
      setShowLoginSignup(true)
    }
  }

  return (
    <> 
      <LoginSignup showModal={showLoginSignup} onCancel={handleLoginSignupCancel} />
      <PostMenuModal showModal={showPostMenu} onCancel={handlePostMenuCancel} posterId={post.userId} userRole={userRole} />
      <ShareModal showModal={showShareModal} onCancel={handleShareModalCancel} postUrl={postUrl} />
      <div className="bg-gray-900 text-gray-100 flex justify-center pt-6">
        <div className="rounded-lg overflow-hidden border border-gray-700 w-full bg-gray-800 mx-2 max-w-xl">
          <div className="w-full flex justify-between p-3">
            <div className="flex">
              <Link className="flex items-center justify-center" to={`/u/${creator.username}`}>
                <img className="rounded-full h-8 w-8 overflow-hidden object-cover" src={creator.imageUrl ? (creator.imageUrl):(defaultAvatarUrl)} alt="profilepic" />
              </Link>
              <Link className="pt-1 ml-2 font-bold text-sm" to={`/u/${creator.username}`}>{creator.username}</Link>
            </div>
            {currentUser ? (
              <div className="flex justify-end">
                <PostMenuButton className="flex justify-end" onClick={() => {setShowPostMenu(true)}} />
              </div>
            ):(null)}
          </div>
          <img className="w-full bg-cover bg-black object-cover" src={post.imageUrl} alt="Pepe pic" onDoubleClick={handleLike} />
          <div className="px-3 pb-2 text-left">
            <div className="pt-2">
              <div className="space-x-2">
                <div className="inline-block cursor-pointer">
                  {liked ? (
                    <HeartIconSolid className="h-7 w-7 text-green-500" onClick={handleLike} />
                  ):(
                    <HeartIconOutline className="h-7 w-7" onClick={handleLike} />
                  )}
                </div>
                <ShareButton onClick={() => {setShowShareModal(true)}} />
              </div>
              <span className="text-sm text-gray-400 font-medium">{numLikes} likes</span>
            </div>          
            <div className="pt-1">
              <div className="mb-2 text-sm">
                <Link className="pt-1 pr-1 font-bold text-sm" to={`/u/${creator.username}`}>{creator.username}</Link> {post.caption}
                {post.timestamp ? (
                    <p className="text-sm text-gray-500">{moment(post.timestamp.toDate()).fromNow()}</p>
                  ) : null}
              </div>
            </div>
            <Comments postId={post.id} />
          </div>
        </div>
      </div>
    </>
  )
}