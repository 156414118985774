import React from 'react';
import { DotsHorizontalIcon } from "@heroicons/react/solid";

export default function PostMenuButton(props) {
  const {onClick} = props;

  return (
    <button className="inline-block cursor-pointer" onClick={onClick}>
      <DotsHorizontalIcon className="h-7 w-7" />
    </button>
  );
}