import { useEffect, useState } from "react";
import { db } from '../../firebase';

export default function usePostId(postId){
  const [post, setPost] = useState({});

  useEffect(() => {
    db.collection('posts').doc(postId).get()
    .then(querySnapshot => {
      const postData = querySnapshot.data();
      setPost({
        id: querySnapshot.id,
        userId: postData.userId,
        imageUrl: postData.imageUrl,
        timestamp: postData.timestamp.toDate().toDateString(),
        likedBy: postData.likedBy
      })
    })
  }, [postId])
  return post;
}