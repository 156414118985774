import React, { useEffect, useState } from "react";
import { db, auth } from '../../firebase';
import { useAuthState } from "react-firebase-hooks/auth";
import { Link } from "react-router-dom";

import firebase from "firebase";
import usePostId from '../hooks/usePostId';

export default function Comments({postId}) {
  const [comments, setComments] = useState([]);
  const [comment, setComment] = useState('');
  const [showComments, setShowComments] = useState(false);
  const [currentUser] = useAuthState(auth);
  const post = usePostId(postId);
  // const user = useUser(post)
  const VISIBLE_COMMENTS = 5;
  const defaultAvatarUrl = 'https://firebasestorage.googleapis.com/v0/b/pepehub-8734e.appspot.com/o/profilePics%2Fdefault-profile-pic.jpeg?alt=media&token=c5a95454-7ab9-40a3-8ba6-d78cfc9b56b9';
  // const shortenedComment = () => {
  //   if (comment.length > 12){
  //     return comment.substring(0, comment.length-1) + "...";
  //   }
  //   return comment;
  // }

  const postComment = (event) => {
    event.preventDefault();
    db.collection("posts").doc(postId).collection("comments").add({
      text: comment,
      username: currentUser?.displayName,
      timestamp: firebase.firestore.FieldValue.serverTimestamp()
    }).then(_ => {
      db.collection("notifications").add({
        timestamp: firebase.firestore.FieldValue.serverTimestamp(),
        userId: post.userId,
        likerUserId: currentUser.uid,
        message: `commented: ${comment}`,
        postId: post.id,
        seen: false
      })
    }).catch((e) => {alert(e)});
    setComment('');
  }

  const toggleShowComments = () => {
    setShowComments(!showComments);
  }

  useEffect(() => {
    let unsubscribe;
    if (postId) {
      unsubscribe = db
        .collection("posts")
        .doc(postId)
        .collection("comments")
        .orderBy("timestamp", "desc")
        .onSnapshot((snapshot) => {
          setComments(snapshot.docs.map((doc) => {
            return {
              id: doc.id,
              ...doc.data()
            }
          }));
        })
    }
    return () => {
      unsubscribe();
    };
  }, [postId])

  return (
    <div>
      <div className="pt-1">
        <div className="mb-2 text-sm">
          <div className="flex">
            {currentUser ? (
              <form className="w-full" onSubmit={postComment}>
                <div className="flex flex-row pt-3">
                  <div className="pr-2 pt-1">
                    <div className="rounded-full h-8 w-8">
                      <img className="rounded-full h-8 w-8 bg-gray-900 flex items-center justify-center overflow-hidden object-cover" src={currentUser.photoURL ? (currentUser.photoURL):(defaultAvatarUrl)} alt="profilepic" />
                    </div>
                  </div>
                  <div className="w-full">
                    <input
                      className="py-2 pl-2 rounded-lg bg-gray-900 text-gray-100 placeholder-gray-600 border border-gray-600 focus:border-green-500 focus:outline-none w-full"
                      type="text"
                      required
                      placeholder="Comment on this epic pepe..."
                      value={comment}
                      onChange={event => setComment(event.target.value)}
                    />
                  </div>
                  <button 
                    type="submit" 
                  />
                </div>
              </form>
            ):(
              <p>Please login to post a comment</p>
            )}
          </div>
        </div>
      </div>
      <div className="mb-2">
        <div className="mb-2 text-sm pt-2">
          {comments.slice(0, showComments ? (comments.length) : (VISIBLE_COMMENTS)).map((c) => (
            <p className="pb-2" key={c.id}>
              <Link to={location => ({ ...location, pathname: `/u/${c.username.toLowerCase()}` })} className="font-bold mr-2">{c.username.toLowerCase()}</Link>
              {c.text}
            </p>
          ))}
        </div>
      </div>
      {comments.length > VISIBLE_COMMENTS ? (
        <button 
        className="text-sm mb-2 text-gray-400 cursor-pointer font-medium"
        onClick={toggleShowComments}
        >
        {showComments ? (
          `Hide comments`
        ):(
          `View all ${comments.length} comments`
        )}
      </button>
      ):null}
    </div>
  )
}