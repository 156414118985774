import { useEffect, useState } from "react";
import { db } from '../../firebase';

export default function useUserId(userId){
  const [user, setUser] = useState({});

  useEffect(() => {
    db.collection('users').doc(userId).get()
    .then(querySnapshot => {
      const userData = querySnapshot.data();
      setUser({
        id: querySnapshot.id,
        username: userData.username,
        imageUrl: userData.imageUrl,
        timestamp: userData.timestamp.toDate().toDateString(),
        followedBy: userData.followedBy,
        role: userData.role
      })

    })
  }, [userId])
  return user;
}
