export default function reducer(state, action) {
  const { type, payload } = action;
  let newState;
  // if you start sending more than one action, you need to
  // differentiate. you can do that by sending actions like:
  // {
  //   type: 'some-unique-id',
  //   payload: <anyValue>
  // }

  switch(type) {
    case 'notification-drawer': {
      const showNotificationDrawer = payload; // payload is different for each case
      // ...
      newState = {
        ...state,
        showNotificationDrawer
      };
    } break;

    case 'new-notification': {
      const newNotification = payload; // payload is different for each case
      // ...
      newState = {
        ...state,
        newNotification
      };
    } break;
    
    default: {
      newState = state;
    }
  }
  
  return newState;
}