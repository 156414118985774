import React from 'react';
import { Link } from 'react-router-dom';
import { auth } from '../../firebase';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useGlobalStateValue } from '../../states/notification-drawer-state';

import useUserId from '../hooks/useUserId';
import usePostId from '../hooks/usePostId';

export default function Notification(props) {
  const {notification} = props;
  const [currentUser] = useAuthState(auth);
  const likerUser = useUserId(notification.likerUserId);
  let post = null;
  //todo: dont use hooks in conditionals, use conditionals in hooks
  if (notification.postId){
    post = usePostId(notification.postId);
  }
  const [{ showNotificationDrawer }, dispatch] = useGlobalStateValue();
  
  function handleLinkClick() {
    dispatch({
      type: 'notification-drawer',
      payload: false,
    })
  }

  return (
    // <div className="w-full rounded-lg bg-gray-800 border border-gray-700">
    //   <div className="flex justify-start h-20">
    //     <Link className="flex items-center pl-3" onClick={handleLinkClick} to={location => ({ ...location, pathname: `/u/${likerUser.username}` })}>
    //       <img className="rounded-full h-12 w-12 absolute overflow-hidden object-cover bg-cover" src={likerUser.imageUrl} alt="profilepic" />
    //     </Link>
    //     <div className="flex items-center text-sm text-gray-200 pl-14">
    //       <p className="w-full"> <Link className="font-bold pr-1" onClick={handleLinkClick} to={`/u/${likerUser.username}`}>{likerUser.username}</Link> {notification.message}</p>
    //     </div>
    //     <div className="flex justify-end w-1/4 h-full align-center ml-auto">
    //       <Link onClick={handleLinkClick} to={`/post/${post.id}`}>
    //         <img className="-mr-2 rounded-lg w-full h-full bg-cover object-cover" src={post.imageUrl} alt="profilepic" />
    //       </Link>
    //     </div>
    //   </div>
    // </div>
    <div className="w-full rounded-lg bg-gray-800 border border-gray-700">
      <div className="grid grid-cols-5 h-20">
        <div className="col-span-1 ml-3 pt-4">
          <Link onClick={handleLinkClick} to={location => ({ ...location, pathname: `/u/${likerUser.username}` })}>
            <img className="rounded-full h-10 w-10 md:h-12 md:w-12 overflow-hidden object-cover bg-cover" src={likerUser.imageUrl} alt="profile pic" />
          </Link>
        </div>
        <div className="col-span-3 text-sm w-full text-gray-200 pt-4 pr-2">
          <p className="w-full line-clamp-2"><Link className="font-bold" onClick={handleLinkClick} to={`/u/${likerUser.username}`}>{likerUser.username}</Link> {notification.message}</p>
        </div>
        <div className="col-span-1 h-20 ml-auto">
          <div className="w-full h-full">
            {post != null ? (
              <Link onClick={handleLinkClick} to={`/post/${post.id}`}>
              <img className="rounded-lg w-full h-full overflow-hidden bg-cover object-cover bg-black" src={post.imageUrl} alt="profilepic" />
            </Link>
            ):(null)}
          </div>
        </div>
      </div>
    </div>
  );
}