import NormalLogo from '../img/pepehub-logo.png';
import NewYearsLogo from '../img/pepehub-newyears-logo.png';
import ValentinesLogo from '../img/pepehub-valentines-logo.png';
import EasterLogo from '../img/pepehub-easter-logo.png';
import HalloweenLogo from '../img/pepehub-logo.gif';
import XmasLogo from '../img/pepehub-xmas-logo.png';
import { Link } from 'react-router-dom';

export default function NavBarLogo() {
  const month = new Date().getMonth();
  let src;
  
  switch(month){
    case 0:
      src = NewYearsLogo
      break
    case 1:
      src = ValentinesLogo
      break
    case 3:
      src = EasterLogo
      break
    case 9:
      src = HalloweenLogo
      break
    case 11:
      src = XmasLogo
      break
    default:
      src = NormalLogo
  }

  return (
    <Link to='/'>
      <div className="flex-shrink-0 flex items-center">
        <img
          className="block lg:hidden h-8 w-auto"
          src={src}
          alt="Pepehub"
        />
        <img
          className="hidden lg:block h-8 w-auto"
          src={src}
          alt="Pepehub"
        />
      </div>
    </Link>
  )
}