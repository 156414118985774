import React, { Fragment, useRef } from 'react';
import { auth } from '../../firebase';
import { useAuthState } from "react-firebase-hooks/auth";
import { Transition, Dialog } from '@headlessui/react';

export default function PostMenuModalDesktop(props) {
  const {showModal, onCancel, posterId, userRole} = props;
  const [currentUser] = useAuthState(auth);
  const cancelButtonRef = useRef(null);

  function renderOptions(){
    if (currentUser && ((currentUser.uid == posterId) || (userRole == "admin") || (userRole == "mod"))){
      return (
        <button className="text-red-500 bg-gray-900 hover:bg-gray-800 w-full rounded-md py-1 focus:outline-none text-center">
          Delete
        </button>
      );
    } else {
      return (
        <button className="text-red-500 bg-gray-900 hover:bg-gray-800 w-full rounded-md py-1 focus:outline-none text-center">
          Report
        </button>
      );
    }
  }

  return (
    <Transition.Root show={showModal} as={Fragment}>
      <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" initialFocus={cancelButtonRef} onClose={onCancel}>
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-gray-900 border border-gray-700 rounded-lg text-center overflow-hidden shadow-xl transform transition-all sm:align-middle max-w-lg w-full px-5">
              <div className="flex justify-center py-5">
                <p className="text-gray-300 font-bold">
                  Post Options
                </p>
              </div>
              <div className="justify-center">
                <div className="pb-5">
                  <div className="space-y-1 w-full">
                    {renderOptions()}
                    <button className="text-gray-400 bg-gray-900 hover:bg-gray-800 w-full rounded-md py-1 focus:outline-none" onClick={onCancel}>
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}