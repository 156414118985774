import React, { useEffect, useState, Fragment } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { auth, db } from '../firebase';
import { useAuthState } from "react-firebase-hooks/auth";
import { Disclosure, Menu, Transition } from '@headlessui/react';
import { MenuIcon, XIcon } from '@heroicons/react/outline';
import NavBarLogo from './NavBarLogo';
import NotificationButton from './buttons/NotificationsButton';
import LoginSignup from './modals/LoginSignup';
import NewPost from './modals/NewPost';

export default function NavBar() {
  const [showLoginSignup, setShowLoginSignup] = useState(false);
  const [showNewPost, setShowNewPost] = useState(false);
  const [currentUser] = useAuthState(auth);
  const [userRole, setUserRole] = useState(null);
  // let role = null;
  let { pathname } = useLocation();

  const navigation = [
    { name: 'Pepe Feed', to: '/', current: pathname==='/' },
    { name: 'Top Pepes', to: '/top-pepes', current: pathname==='/top-pepes' },
    { name: 'Seasonal Pepes', to: '/seasonal-pepes', current: pathname==='/seasonal-pepes' },
  ]
  
  useEffect(() => {
    if (currentUser){
      db.collection('users').doc(currentUser.uid).get()
      .then(querySnapshot => {
        const userData = querySnapshot.data();
        setUserRole(userData.role);
      })
    } 
  }, [currentUser])

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }

  const handleLoginSignupCancel = () => {
    setShowLoginSignup(false);
  }

  const handleNewPostCancel = () => {
    setShowNewPost(false);
  }

  function renderModDashboard(){
    return userRole == 'admin' || userRole == 'mod' ? (
      <Menu.Item>
      {({ active }) => (
        <Link className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-300 hover:bg-gray-800')} to={`/u/${currentUser.displayName}`}>
          Mod Dashboard
        </Link>
      )}
    </Menu.Item>
    ):(null)
  }

  return (
    <div className="App bg-gray-900 pb-16">
      <LoginSignup showModal={showLoginSignup} onCancel={handleLoginSignupCancel} />
      <NewPost showModal={showNewPost} onCancel={handleNewPostCancel} />
      {/* NavBar */}
      <Disclosure as="nav" className="bg-gray-800 fixed w-full">
        {({ open, close }) => (
          <>
            <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
              <div className="relative flex items-center justify-between h-16">
                <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                  {/* Mobile menu button*/}
                  <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none">
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <XIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                </div>
                <div className="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
                  <NavBarLogo />
                  <div className="hidden sm:block sm:ml-6">
                    <div className="flex space-x-4">
                      {navigation.map((item) => (
                        <Link
                          key={item.name}
                          to={item.to}
                          className={classNames(
                            item.current ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                            'px-3 py-2 rounded-md text-sm font-medium'
                          )}
                          aria-current={item.current ? 'page' : undefined}
                        >
                          {item.name}
                        </Link>
                      ))}
                    </div>
                  </div>
                </div>
                {/* Profile info / login+signup buttons */}
                {currentUser ? (
                  <>
                    <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                      <NotificationButton />
                      {/* Profile dropdown */}
                      <Menu as="div" className="ml-3 relative">
                        <div>
                          <Menu.Button className="bg-gray-800 flex text-sm rounded-full focus:outline-none">
                            <span className="sr-only">Open user menu</span>
                            <img
                              className="h-8 w-8 rounded-full object-cover bg-gray-900"
                              src={currentUser.photoURL}
                              alt="Your profile picture"
                            />
                          </Menu.Button>
                        </div>
                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-gray-900 border border-gray-700 ring-1 ring-black ring-opacity-5 focus:outline-none">
                            <Menu.Item>
                              {({ active }) => (
                                <Link className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-300 hover:bg-gray-800')} to={`/u/${currentUser.displayName}`}>
                                  Your Profile
                                </Link>
                              )}
                            </Menu.Item>
                            <Menu.Item>
                              {({ active }) => (
                                <button
                                  className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-300 hover:bg-gray-800 w-full')}
                                  onClick={() => setShowNewPost(true)}
                                >
                                  Post a Pepe
                                </button>
                              )}
                            </Menu.Item>
                            {renderModDashboard()}
                            <Menu.Item>
                              {({ active }) => (
                                <button
                                  className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-300 hover:bg-gray-800 w-full')}
                                  onClick={() => auth.signOut()}
                                >
                                  Sign out
                                </button>
                              )}
                            </Menu.Item>
                          </Menu.Items>
                        </Transition>
                      </Menu>
                    </div>
                  </>
                ):(
                  // if the user is not logged in
                  <>
                    <div className="absolute inset-y-0 right-0 flex items-center sm:static sm:inset-auto sm:pr-0">
                      <button
                        type="button"
                        className="mt-3 mb-3 sm:mb-0 md:mb-0 lg:mb-0 sm:mt-0 md:mt-0 lg:mt-0 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-gray-800 text-base font-medium text-gray-300 hover:border-green-500 hover:text-green-500 focus:outline-none sm:mr-3 sm:ml-3 sm:w-auto sm:text-sm"
                        onClick={() => setShowLoginSignup(true)}
                      >
                        Login
                      </button>
                    </div>
                  </>
                )}
              </div>
            </div>
            <Disclosure.Panel className="sm:hidden">
              <div className="px-2 pt-2 pb-3 space-y-1">
                {navigation.map((item) => (
                  <Link
                    key={item.name}
                    to={item.to}
                    className={classNames(
                      item.current ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                      'block px-3 py-2 rounded-md text-base font-medium'
                    )}
                    aria-current={item.current ? 'page' : undefined}
                    onClick={() => {close()}}
                  >
                    {item.name}
                  </Link>
                ))}
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </div>
  );
}