import React, { Fragment, useRef } from 'react';
import { Transition, Dialog } from '@headlessui/react';
import toast from 'react-hot-toast';
import { ClipboardCopyIcon } from "@heroicons/react/outline";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-regular-svg-icons';
import { faTwitter, faRedditAlien, faFacebook, faPinterest, faTelegram, faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import {
  EmailShareButton,
  FacebookShareButton,
  PinterestShareButton,
  RedditShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";

export default function ShareModalDesktop(props) {
  const {showModal, onCancel, postUrl} = props;
  const cancelButtonRef = useRef(null);
  const textAreaRef = useRef(null);

  function copyToClipboard(e) {
    textAreaRef.current.select();
    document.execCommand('copy');
    toast.success("Copied!",
      {
        style: {
          border: '2px solid #111827',
          borderRadius: '5px',
          background: '#1F2937',
          color: '#fff',
        },
      }
    )
  }

  return (
    <Transition.Root show={showModal} as={Fragment}>
      <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" initialFocus={cancelButtonRef} onClose={onCancel}>
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-gray-900 border border-gray-700 rounded-lg text-center overflow-hidden shadow-xl transform transition-all sm:align-middle max-w-lg w-full px-5">
              <div className="flex justify-center py-5">
                <p className="text-gray-300 font-bold">
                  Share Pepe
                </p>
              </div>
              <div className="justify-center">
                <div className="pb-5">
                  <div className="space-y-3 w-full">
                    <input
                      className="py-2 pl-2 rounded-lg bg-gray-900 text-gray-300 border border-green-500 focus:outline-none w-full md:w-7xl"
                      type="text"
                      value={postUrl}
                      rows="1"
                      ref={textAreaRef}
                      readOnly
                    />
                    <span className="absolute inset-y-0 right-0 flex items-center pr-6 pb-5">
                      <button 
                      className="focus:outline-none focus:shadow-outline bg-gray-900 rounded-xl" 
                      onClick={copyToClipboard}
                      >
                        <ClipboardCopyIcon className="w-8 h-8 text-gray-400 hover:text-gray-300" />
                      </button>
                    </span>
                  </div>
                </div>
              </div>
              <div className="bg-gray-900 sm:px-6 sm:flex-row-reverse sm:flex sm:justify-center md:flex md:justify-center max-w-lg pb-5">
                <div className="space-x-3">
                  <TwitterShareButton url={postUrl} title="Checkout this epic pepe from PepeHub 🐸 #pepehub #averagepepenjoyer #poggers"> 
                    <div className="text-blue-400 hover:text-blue-500">
                      <FontAwesomeIcon size="2x" icon={faTwitter} />
                    </div>
                  </TwitterShareButton>
                  <RedditShareButton url={postUrl} title="Tweet" tags={["#hello", "#hi"]} caption="This is a poggers post"> 
                    <div className="text-white hover:text-yellow-600">
                      <FontAwesomeIcon size="2x" icon={faRedditAlien} />
                    </div>
                  </RedditShareButton>
                  <FacebookShareButton url={postUrl} title="Tweet" tags={["#hello", "#hi"]} caption="This is a poggers post"> 
                    <div className="text-blue-600 hover:text-blue-700">
                      <FontAwesomeIcon size="2x" icon={faFacebook} />
                    </div>
                  </FacebookShareButton>
                  <PinterestShareButton url={postUrl} title="Tweet" tags={["#hello", "#hi"]} caption="This is a poggers post"> 
                    <div className="text-red-700 hover:text-red-800">
                      <FontAwesomeIcon size="2x" icon={faPinterest} />
                    </div>
                  </PinterestShareButton>
                  <TelegramShareButton url={postUrl} title="Tweet" tags={["#hello", "#hi"]} caption="This is a poggers post"> 
                    <div className="text-blue-400 hover:text-blue-500">
                      <FontAwesomeIcon size="2x" icon={faTelegram} />
                    </div>
                  </TelegramShareButton>
                  <WhatsappShareButton url={postUrl} title="Tweet" tags={["#hello", "#hi"]} caption="This is a poggers post"> 
                    <div className="text-green-500 hover:text-green-600">
                      <FontAwesomeIcon size="2x" icon={faWhatsapp} />
                    </div>
                  </WhatsappShareButton>
                  <EmailShareButton url={postUrl} title="Tweet" tags={["#hello", "#hi"]} caption="This is a poggers post"> 
                    <div className="text-gray-300 hover:text-gray-400">
                      <FontAwesomeIcon size="2x" icon={faEnvelope} />
                    </div>
                  </EmailShareButton>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}