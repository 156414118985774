import React, { useState, useEffect } from "react";
import PostMenuModalMobile from "./PostMenuModalMobile";
import PostMenuModalDesktop from "./PostMenuModalDesktop";

export default function PostMenuModal(props) {
  const {showModal, onCancel, posterId, userRole} = props;

  const [width, setWidth] = useState(window.innerWidth);
  let isMobile = (width < 768)

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);
  
  return (isMobile ? (
    <PostMenuModalMobile showModal={showModal} onCancel={onCancel} posterId={posterId} userRole={userRole} />
  ):(
    <PostMenuModalDesktop showModal={showModal} onCancel={onCancel} posterId={posterId} userRole={userRole} />
  ));
}