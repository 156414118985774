import React, { Fragment, useEffect, useState } from 'react';
import { BellIcon } from '@heroicons/react/outline'
import { useGlobalStateValue } from '../../states/notification-drawer-state';

export default function NotificationButton() {
  const [{ showNotificationDrawer, newNotification }, dispatch] = useGlobalStateValue();

  function handleDrawer() {
    dispatch({
      type: 'notification-drawer',
      payload: true,
    });
    dispatch({
      type: 'new-notification',
      payload: false,
    });
  }

  return (
    <>
      <button 
        className="bg-gray-800 p-1 rounded-full text-gray-400 hover:text-white focus:outline-none" 
        onClick={handleDrawer}
      >
        <span className="sr-only">View notifications</span>
        {newNotification ? (
          <>
            <span className="animate-ping absolute inline-flex h-2 w-2 rounded-full bg-red-400"></span>
            <span className="absolute inline-flex h-2 w-2 rounded-full bg-red-500"></span>
          </>
        ):(
          null
        )}
        <BellIcon className="h-7 w-7" aria-hidden="true" />
      </button>
    </>
  );
}