import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { db } from '../firebase';
import Post from '../components/posts/Post';

export default function PostView() {
  const { urlPostId } = useParams();
  const [post, setPost] = useState(null);

  // firebase
  useEffect(() => {
    db.collection('posts').doc(urlPostId).get()
    .then((querySnapshot) => {
      // debugger;
      setPost({
        id: querySnapshot.id,
        ...querySnapshot.data()
      })
    }) 
    .catch((e) => {
      //404
      console.error(e);
      setPost([]);
    });
  }, [urlPostId]);

  return (
    <div className="App bg-gray-800">
      {post && <Post post={post} />}
    </div>
  );
}