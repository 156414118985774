import React, { Fragment, useState, useEffect } from 'react';
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, db } from '../../firebase';
import { XIcon } from '@heroicons/react/outline';
import { Transition, Dialog } from '@headlessui/react';
import Notification from './Notification';
import { useGlobalStateValue } from '../../states/notification-drawer-state';

export default function NotificationDrawer() {
  const [currentUser] = useAuthState(auth);
  const [notifications, setNotifications] = useState([]);
  const [{ showNotificationDrawer, newNotification }, dispatch] = useGlobalStateValue();

  useEffect(() => {
    let unsubscribe;
    if (currentUser) {
      unsubscribe = db
        .collection("notifications")
        .where("userId", "==", currentUser.uid)
        .orderBy("timestamp", "desc")
        .onSnapshot((snapshot) => {
          const notificationsMap = snapshot.docs.map((doc) => {
            return {
              id: doc.id,
              ...doc.data()
          }})
          setNotifications(notificationsMap);
          snapshot.forEach((doc) => {
            if (!doc.data().seen) {
              dispatch({
                type: 'new-notification',
                payload: true,
              })
              db.collection('notifications').doc(doc.id).update({
                seen: true
              })
            }
          })
        })
    }
    return () => {
      unsubscribe?.();
    };
  }, [currentUser])

  function handleCloseDrawer() {
    dispatch({
      type: 'notification-drawer',
      payload: false,
    });
  }

  function renderNotifications() {
    if (notifications.length){
      return (
        notifications.map((n) => {
          return <Notification key={n.id} notification={n} />
        })
      )
    } else {
      return (
        <div className="rounded-lg bg-gray-900 mt-6 w-full border-dashed border-2 md:border-4 border-gray-700 p-5 py-10">
          <div className="flex justify-center w-full text-gray-500 font-bold">
            <p>You do not have any notifications yet 🥲</p>
          </div>
        </div>
      )
    }
  }

  function handleClearNotifications() {
    db.collection("notifications").where("userId", "==", currentUser.uid).get()
    .then(querySnapshot => {
        querySnapshot.forEach(doc => {
          doc.ref.delete();
        })
    });
  }

  return (
    <Transition.Root show={showNotificationDrawer} as={Fragment}>
      <Dialog as="div" className="fixed inset-0 overflow-hidden" onClose={handleCloseDrawer}>
        <div className="absolute inset-0 overflow-hidden">
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="absolute inset-0 bg-black bg-opacity-75 transition-opacity" />
          </Transition.Child>
          <div className="fixed inset-y-0 right-0 pl-10 max-w-full flex">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-300"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-300"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className="relative w-screen max-w-md">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-100"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute top-0 right-0 mr-3 pr-2 flex sm:-ml-10 sm:pr-4 bg-gray-900">
                    <div className="px-4 sm:px-6 bg-gray-900 w-full mr-28 md:mr-48 pt-8 pb-2">
                      <Dialog.Title className="text-2xl font-extrabold text-gray-300">Notifications</Dialog.Title>
                    </div>
                    <button
                      type="button"
                      className="rounded-md text-gray-300 hover:text-red-400 focus:outline-none pt-6"
                      onClick={handleCloseDrawer}
                    >
                      <span className="sr-only">Close panel</span>
                      <XIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                </Transition.Child>
                <div className="h-full flex flex-col py-6 pt-14 bg-gray-900 shadow-xl overflow-y-scroll">
                  <div className="mt-6 relative flex-1 px-4 sm:px-6 space-y-3 z-5">
                    <div className="pl-1 pb-2">
                      <button className="rounded-full border px-3 text-gray-300 border-gray-300 hover:border-green-400 hover:text-green-400 focus:outline-none"
                        onClick={handleClearNotifications}
                      >
                        Clear
                      </button>
                    </div>
                    {renderNotifications()}
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}