import React, { Fragment } from 'react';
import { auth } from '../../firebase';
import { useAuthState } from "react-firebase-hooks/auth";
import { XIcon } from '@heroicons/react/outline';
import { Transition, Dialog } from '@headlessui/react';

export default function PostMenuModalMobile(props) {
  const {showModal, onCancel, posterId, userRole} = props;
  const [currentUser] = useAuthState(auth);

  function renderOptions(){
    if (currentUser && ((currentUser.uid == posterId) || (userRole == "admin") || (userRole == "mod"))){
      return (
        <button className="text-red-500 bg-gray-900 hover:bg-gray-800 w-full rounded-md py-1 focus:outline-none text-center">
          Delete
        </button>
      );
    } else {
      return (
        <button className="text-red-500 bg-gray-900 hover:bg-gray-800 w-full rounded-md py-1 focus:outline-none text-center">
          Report
        </button>
      );
    }
  }

  return (
    <Transition.Root show={showModal} as={Fragment}>
      <Dialog as="div" className="fixed inset-0 overflow-hidden" onClose={onCancel}>
        <div className="absolute inset-0 overflow-hidden">
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="absolute inset-0 bg-black bg-opacity-75 transition-opacity" />
          </Transition.Child>
          <div className="fixed inset-x-0 bottom-0 max-w-full flex">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-300"
              enterFrom="translate-y-full"
              enterTo="translate-y-0"
              leave="transform transition ease-in-out duration-300"
              leaveFrom="translate-y-0"
              leaveTo="translate-y-full"
            >
              <div className="relative w-screen">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-100"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute top-0 left-0 pr-2 flex sm:pr-4 pl-5 pt-6">
                    <button
                      type="button"
                      className="rounded-md text-gray-300 hover:text-white focus:outline-none"
                      onClick={onCancel}
                    >
                      <span className="sr-only">Close panel</span>
                      <XIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                </Transition.Child>
                <div className="h-full flex flex-col py-6 bg-gray-900 overflow-y-scroll">
                  <div className="text-center">
                    <Dialog.Title className="text-xl font-bold text-gray-300">Post Options</Dialog.Title>
                  </div>
                    <div className="inline-block align-bottom bg-gray-900 rounded-lg text-center overflow-hidden transform transition-all w-full px-5 pt-5">
                      <div className="justify-center ">
                        <div className="pb-3 text-center">
                          <div className="space-y-3 w-full text-center">
                            {renderOptions()}
                            <button className="text-gray-400 bg-gray-900 hover:bg-gray-800 w-full rounded-md py-1 focus:outline-none text-center" onClick={onCancel}>
                              Cancel
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}