import React, { useState, Fragment, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useAuthState } from "react-firebase-hooks/auth";
import { db, storage, auth } from '../../firebase';
import { Transition, Dialog } from '@headlessui/react';

export default function EditProfile(props) {
  const {showModal, onCancel} = props;
  const cancelButtonRef = useRef(null);
  const [currentUser] = useAuthState(auth);
  
  const [image, setImage] = useState(null);
  const [progress, setProgress] = useState(0);
  const [showProgress, setShowProgress] = useState(false);

  const history = useHistory();

  const handleChange = (e) => {
    if (!e.target.files[0] || e.target.files[0].length === 0) {
      setImage(null);
      return;
    }
    if (e.target.files[0]) {
      setImage(e.target.files[0]);
    }
  }

  // function changeUsername(){

  //   if (currentUser.displayName !== newUsername){
  //     return currentUser.updateProfile({
  //       displayName: newUsername
  //     })
  //     .then(
  //       db.collection("users").doc(currentUser.uid).update({
  //         username: newUsername
  //       })
  //     )
  //     .then(() => {
  //       history.push(`/u/${newUsername}`);
  //       history.go(0);
  //       // console.log({currentUser})
  //     })
  //   } else return Promise.resolve();
  // }

  const handleUpload = () => {
    setShowProgress(true);
      const imageId = currentUser.uid
      const uploadTask = storage.ref(`profilePics/userProfilePics/${imageId}`).put(image);

      return uploadTask.on(
        "state changed",
        (snapshot) => {
          const progressBar = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          setProgress(progressBar);
        },
        (error ) => {
          alert(error.message);
        },
        () => {
          storage
            .ref("profilePics/userProfilePics")
            .child(imageId)
            .getDownloadURL()
            .then(url => {
              // post image
              currentUser.updateProfile({
                photoURL: url
              })
              db.collection("users").doc(currentUser.uid).update({
                imageUrl: url
              })
              .then(() => {
                history.go(0);
              });
              setProgress(0);
              setImage(null);
              setShowProgress(false);
            })
        }
      )
  }

  return (
    <Transition.Root show={showModal} as={Fragment}>
      <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" initialFocus={cancelButtonRef} onClose={onCancel}>
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-gray-800 rounded-lg text-center overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full px-5">
              <div className="flex justify-center pt-5">
                <p className="text-gray-300 font-bold">
                  Edit Profile
                </p>
              </div>
              <div className="justify-center">
                <div className="flex justify-center w-full pt-8">
                  <img 
                    className="rounded-full h-44 w-44 flex items-center justify-center overflow-hidden object-cover"
                    src={image
                      ? (URL.createObjectURL(image)):(currentUser?.photoURL)}
                    alt="Avatar"
                  />
                </div>
                <div className="py-8 justify-center space-y-3">
                  <label
                    className="w-full inline-flex justify-center rounded-md border border-indigo-500 shadow-sm px-4 py-2 bg-indigo-500 text-base font-medium text-white hover:bg-indigo-700 hover:border-indigo-700 focus:outline-none sm:w-auto sm:text-sm"
                  >
                    Change Profile Picture
                    <input
                      className="hidden"
                      accept="image/*"
                      type="file"
                      onChange={handleChange}
                    />
                  </label>
                </div>
                <div className="pb-3 md:pb-10">
                  <div className="space-y-3 w-full">
                    <input
                      className="py-2 pl-2 rounded-lg bg-gray-800 text-gray-500 border border-gray-600 focus:border-green-500 focus:outline-none w-full md:w-7/12"
                      type="text"
                      placeholder="username"
                      value={currentUser?.displayName}
                      disabled
                      // onChange={(e) => setNewUsername(e.target.value)}
                    />
                    <input
                      className="py-2 pl-2 rounded-lg bg-gray-800 text-gray-500 border border-gray-600 focus:border-green-500 focus:outline-none w-full md:w-7/12"
                      type="text"
                      placeholder="email"
                      value={currentUser?.email}
                      disabled
                      // onChange={(e) => setNewEmail(e.target.value)}
                    />
                    {showProgress ? (
                      <progress 
                        className="overflow-hidden h-2 text-xs flex rounded bg-blue-500 text-blue-500 w-full"
                        value={progress}
                        max="100"
                      />
                    ): null}
                  </div>
                </div>
              </div>
              <div className="bg-gray-800 pb-3 sm:px-6 sm:flex sm:flex-row-reverse sm:flex sm:justify-center md:flex md:justify-center max-w-lg">
                <button
                  type="button"
                  className="w-full inline-flex justify-center rounded-md border border-green-500 shadow-sm px-4 py-2 bg-green-500 text-base font-medium text-white hover:bg-green-700 hover:border-green-700 focus:outline-none sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={handleUpload}
                >
                  Save
                </button>
                <button
                  type="button"
                  className="mt-3 sm:mt-0 md:mt-0 lg:mt-0 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-gray-800 text-base font-medium text-gray-300 hover:border-red-500 hover:text-red-500 focus:outline-none sm:mr-3 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={onCancel}
                  ref={cancelButtonRef}
                >
                  Cancel
                </button>
              </div> 
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}