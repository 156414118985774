import React from "react";

export default function Footer() {

  return (
    <>
      <div>
        <footer className="text-gray-300 bg-gray-800 body-font">
          <div className="container flex flex-col items-center px-8 py-8 mx-auto max-w-6xl sm:flex-row mt-3">
            <a href="https://liamcodes.com" className="text-xl font-bold leading-none text-gray-300 select-none logo">LiamCodes</a>
            <p className="mt-4 text-sm text-gray-300 sm:ml-4 sm:pl-4 sm:border-l sm:border-gray-800 sm:mt-0">
              © 2021 LiamCodes
            </p>
            <span className="inline-flex justify-center mt-4 space-x-5 sm:ml-auto sm:mt-0 sm:justify-start">
              <a href="https://github.com/liammcodes" className="text-red-400 hover:text-pink-900 text-2xl" target="_blank">
                <em className="fab fa-github"></em>
              </a>

              <a href="https://www.instagram.com/liam_codes/" className="text-red-400 hover:text-pink-900 text-2xl" target="_blank">
                <em className="fab fa-instagram"></em>
              </a>

              <a href="https://www.twitch.tv/liama6" className="text-red-400 hover:text-pink-900 text-2xl" target="_blank">
                <em className="fab fa-twitch"></em>
              </a>

              <a href="https://twitter.com/liammcodes" className="text-red-400 hover:text-pink-900 text-2xl" target="_blank">
                <em className="fab fa-twitter"></em>
              </a>
            </span>
          </div>
        </footer>
      </div>
    </>
  )
}