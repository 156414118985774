import React, { Fragment, useRef } from 'react';
import { XIcon } from '@heroicons/react/outline';
import { Transition, Dialog } from '@headlessui/react';
import { ClipboardCopyIcon } from "@heroicons/react/outline";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-regular-svg-icons';
import { faTwitter, faRedditAlien, faFacebook, faPinterest, faTelegram, faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import {
  EmailShareButton,
  FacebookShareButton,
  PinterestShareButton,
  RedditShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";
import toast from 'react-hot-toast';

export default function ShareModalMobile(props) {
  const { showModal, onCancel, postUrl } = props;
  const textAreaRef = useRef(null);

  function copyToClipboard(e) {
    textAreaRef.current.select();
    document.execCommand('copy');
    toast.success("Copied!",
      {
        style: {
          border: '2px solid #111827',
          borderRadius: '5px',
          background: '#1F2937',
          color: '#fff',
        },
      }
    )
  }

  return (
    <Transition.Root show={showModal} as={Fragment}>
      <Dialog as="div" className="fixed inset-0 overflow-hidden" onClose={onCancel}>
        <div className="absolute inset-0 overflow-hidden">
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="absolute inset-0 bg-black bg-opacity-75 transition-opacity" />
          </Transition.Child>
          <div className="fixed inset-x-0 bottom-0 max-w-full flex">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-300"
              enterFrom="translate-y-full"
              enterTo="translate-y-0"
              leave="transform transition ease-in-out duration-300"
              leaveFrom="translate-y-0"
              leaveTo="translate-y-full"
            >
              <div className="relative w-screen">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-100"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute top-0 left-0 pr-2 flex sm:pr-4 pl-5 pt-6">
                    <button
                      type="button"
                      className="rounded-md text-gray-300 hover:text-white focus:outline-none"
                      onClick={onCancel}
                    >
                      <span className="sr-only">Close panel</span>
                      <XIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                </Transition.Child>
                <div className="h-full flex flex-col py-6 bg-gray-900 overflow-y-scroll">
                  <div className="text-center">
                    <Dialog.Title className="text-xl font-bold text-gray-300">Share a Pepe!</Dialog.Title>
                  </div>
                  
                    <div className="inline-block align-bottom bg-gray-900 rounded-lg text-center overflow-hidden transform transition-all sm:align-middle max-w-lg w-full px-5 pt-5">
                      <div className="justify-center">
                        <div className="pb-5">
                          <div className="space-y-3 w-full">
                            <input
                              className="py-2 pl-2 rounded-lg bg-gray-900 text-gray-300 border border-gray-400 focus:border-green-500 focus:outline-none w-full md:w-7xl pr-10"
                              type="text"
                              value={postUrl}
                              rows="1"
                              ref={textAreaRef}
                              readOnly
                            />
                            <span className="absolute inset-y-0 right-0 flex items-center pr-6 pb-16">
                              <button 
                              className="focus:outline-none bg-gray-900" 
                              onClick={copyToClipboard}
                              >
                                <ClipboardCopyIcon className="w-8 h-8 text-gray-400 hover:text-gray-300" />
                              </button>
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="bg-gray-900 sm:px-6 sm:flex-row-reverse sm:flex sm:justify-center md:flex md:justify-center max-w-lg pb-5">
                        <div className="space-x-3">
                          <TwitterShareButton url={postUrl} title="Checkout this epic pepe from PepeHub 🐸 #pepehub #averagepepenjoyer #poggers"> 
                            <div className="text-blue-400 hover:text-blue-500">
                              <FontAwesomeIcon size="2x" icon={faTwitter} />
                            </div>
                          </TwitterShareButton>
                          <RedditShareButton url={postUrl} title="Tweet" tags={["#hello", "#hi"]} caption="This is a poggers post"> 
                            <div className="text-white hover:text-yellow-600">
                              <FontAwesomeIcon size="2x" icon={faRedditAlien} />
                            </div>
                          </RedditShareButton>
                          <FacebookShareButton url={postUrl} title="Tweet" tags={["#hello", "#hi"]} caption="This is a poggers post"> 
                            <div className="text-blue-600 hover:text-blue-700">
                              <FontAwesomeIcon size="2x" icon={faFacebook} />
                            </div>
                          </FacebookShareButton>
                          <PinterestShareButton url={postUrl} title="Tweet" tags={["#hello", "#hi"]} caption="This is a poggers post"> 
                            <div className="text-red-700 hover:text-red-800">
                              <FontAwesomeIcon size="2x" icon={faPinterest} />
                            </div>
                          </PinterestShareButton>
                          <TelegramShareButton url={postUrl} title="Tweet" tags={["#hello", "#hi"]} caption="This is a poggers post"> 
                            <div className="text-blue-400 hover:text-blue-500">
                              <FontAwesomeIcon size="2x" icon={faTelegram} />
                            </div>
                          </TelegramShareButton>
                          <WhatsappShareButton url={postUrl} title="Tweet" tags={["#hello", "#hi"]} caption="This is a poggers post"> 
                            <div className="text-green-500 hover:text-green-600">
                              <FontAwesomeIcon size="2x" icon={faWhatsapp} />
                            </div>
                          </WhatsappShareButton>
                          <EmailShareButton url={postUrl} title="Tweet" tags={["#hello", "#hi"]} caption="This is a poggers post"> 
                            <div className="text-gray-300 hover:text-gray-400">
                              <FontAwesomeIcon size="2x" icon={faEnvelope} />
                            </div>
                          </EmailShareButton>
                        </div>
                      </div>
                    </div>
                  
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}